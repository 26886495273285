import { fetchExchange } from '@urql/core';
import { authExchange, hasAuthLikeError } from '../auth.js';
const FIDANT_CREDS_KEY = Symbol('Key for per-operation credentials');
// https://commerce.nearform.com/open-source/urql/docs/advanced/authentication
const remoteAuthConfigurator = (auth) => {
    let credentials = null;
    return {
        willAuthError: _op => credentials == null,
        // Called on first operation; or if we return willAuthError for an operation before executing, or didAuthError after.
        async refreshAuth() {
            credentials = await auth();
        },
        // Called for every operation.
        addAuthToOperation: op => {
            if (!credentials) {
                return op;
            }
            const fetchOptions = typeof op.context.fetchOptions === 'function' ? op.context.fetchOptions() : op.context.fetchOptions || {};
            const headers = new Headers(fetchOptions.headers);
            // This is a dummy token! During development, it ensures that Next.js treats this as an uncached request.
            // Also - YIKES: I used 'base64url' which apparently is not defined by nextjs's shim. This caused an exception,
            // but it seems to be swallowed (possibly because I don't have the logging adapter plugged in on the client side yet).
            const token = credentials.token || [credentials.role, credentials.sub].map(x => Buffer.from(x).toString('base64')).join('.');
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            if (credentials.headers) {
                const credHeaders = new Headers(credentials.headers);
                credHeaders.forEach((value, key) => void headers.set(key, value));
            }
            op.context[FIDANT_CREDS_KEY] = credentials;
            op.context.fetchOptions = {
                credentials: 'include', // or 'same-origin'?
                mode: 'cors', // or 'same-origin'?
                ...fetchOptions,
                headers,
            };
            return op;
            // Beware: this copies the operation, it does not mutate it!
            // return util.appendHeaders(op, {
            //   ...(credentials.headers ? Object.fromEntries(credentials.headers.entries()) : {}),
            //   ...(credentials.token ? { Authorization: `Bearer ${credentials.token}` } : {}),
            // });
        },
        didAuthError: (error, _op) => [401, 403].includes(error.response?.status ?? null) ||
            hasAuthLikeError(error),
    };
};
// TODO: @urql/exchange-persisted-fetch
export const getBackend = ({ auth, ...opts }) => ({
    ...opts,
    exchanges: [authExchange(remoteAuthConfigurator(auth)), fetchExchange],
});
