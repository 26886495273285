import { map, pipe } from 'wonka';
// Round-trip the results and variables through JSON serialization to ensure that they are independent.
// This is reduces coupling when using the `executionExchange`.
export const roundTripExchange = () => ({ forward }) => (operations$) => pipe(operations$, map((op) => {
    if (op.variables === undefined) {
        return op;
    }
    if (op.kind === 'query' || op.kind === 'mutation' || op.kind === 'subscription') {
        op.variables = JSON.parse(JSON.stringify(op.variables));
    }
    return op;
}), forward, map((result) => {
    if (result.data != null) {
        result.data = JSON.parse(JSON.stringify(result.data));
    }
    return result;
}));
