import { GraphQLBoolean, GraphQLFloat, GraphQLID, GraphQLInt, GraphQLString } from 'graphql';
import { GraphQLISO8601Duration, GraphQLTimestamp, GraphQLURL } from 'graphql-scalars';
// The "Date" <-> number conversion from the GraphQLTimestamp scalar gets messed up by the roundTripExchange, because
// those Date objects then get JSONified which results in strings.
// For now, we're just going to skip it in order to preserve the current timestamp wire format as a number.
GraphQLTimestamp;
export const ScalarTypes = {
    Boolean: GraphQLBoolean,
    Float: GraphQLFloat,
    ID: GraphQLID,
    Int: GraphQLInt,
    String: GraphQLString,
    ISO8601Duration: GraphQLISO8601Duration,
    JSON: (value) => value,
    // The "Date" <-> number conversion from the GraphQLTimestamp scalar gets messed up by the roundTripExchange, because
    // those Date objects then get JSONified which results in strings.
    // For now, preserve the current timestamp wire format as a number.
    Timestamp: (_) => (typeof _ === 'number' ? _ : new Date(_).valueOf()),
    URL: GraphQLURL,
};
