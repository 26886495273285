// GraphQLYoga returns an extension of "{ http: { status: number } }" for HTTP errors.
// https://the-guild.dev/graphql/yoga-server/docs/features/error-masking#modifying-http-status-codes-and-headers
function* iterPossibleHttpStatusOrCodesFromGraphQLErrorExtensions(extensions) {
    if ('code' in extensions) {
        yield extensions['code'];
    }
    if ('http' in extensions && typeof extensions['http'] === 'object' && extensions['http'] != null) {
        if ('status' in extensions['http']) {
            yield extensions['http']['status'];
        }
    }
}
/**
 * Yields all of the immediate values of potential HTTP status messages (`FORBIDDEN`) or codes (`401`) from an error.
 * This does not descend into the error chain or even check the types of the yielded values.
 */
export function* iterPossibleHttpStatusOrCodesFromError(e) {
    // GraphQLError.extensions
    if ('extensions' in e && typeof e.extensions === 'object' && e.extensions !== null) {
        yield* iterPossibleHttpStatusOrCodesFromGraphQLErrorExtensions(e.extensions);
    }
    // CombinedError.response
    if ('response' in e &&
        typeof e.response === 'object' &&
        e.response !== null &&
        typeof e.response === 'object' &&
        'status' in e.response) {
        yield e.response['status'];
    }
    // NOTE: CombinedError.networkError, etc are walked as part of the error chain.
}
