import { createClient } from '@urql/core';
import loggingExchange from '@fidant-io/urql-exchange-logging';
import { createExchange as cacheExchange, defaultCache } from './cache.js';
import { nativeCombinedAggregateErrorExchange } from './CombinedAggregateError.js';
import { scalarExchange } from './scalars.js';
export const createConfiguredClient = ({ backend: { exchanges: backendExchanges, ...backendOpts }, cache = defaultCache, scalars = 'default', logger, ...opts }) => createClient({
    ...opts,
    ...backendOpts,
    exchanges: [
        ...(logger ? [loggingExchange({ logger })] : []),
        ...(scalars === 'raw' ? [] : [scalarExchange(scalars)]),
        cacheExchange(cache),
        nativeCombinedAggregateErrorExchange(),
        ...backendExchanges,
    ],
});
