import assert from 'assert';
import { onPush, pipe } from 'wonka';
const FIDANT_LOGGER_KEY = Symbol('Key for per-operation logger');
const FIDANT_LOG_CONTEXT_KEY = Symbol('Key for per-operation logging context');
export const getOperationLogger = (op) => op.context[FIDANT_LOGGER_KEY];
export const getOperationLogContext = (op) => op.context[FIDANT_LOG_CONTEXT_KEY];
const toLoggableOperation = (op) => {
    const names = op.query.definitions
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        .filter((def) => def.kind === 'OperationDefinition')
        .map(op => op.name?.value)
        .filter((n) => !!n);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const opName = names.length === 1 ? names[0] : names;
    const { query: fullQuery, context: { [FIDANT_LOGGER_KEY]: _logger, [FIDANT_LOG_CONTEXT_KEY]: _ctx, ...context }, ...operation } = op;
    // Omit the full query document from the log: just the __meta__ field which has the hash.
    if ('__meta__' in op.query) {
        const query = { __meta__: op.query.__meta__ };
        return { opName, op: { query, context, ...operation } };
    }
    else {
        return { opName, op: { query: fullQuery, context, ...operation } };
    }
};
export const loggingExchange = ({ logger: outerLogger }) => ({ forward }) => ops$ => pipe(ops$, onPush(operation => {
    if (operation.kind !== 'teardown') {
        const context = (operation.context[FIDANT_LOG_CONTEXT_KEY] ??= {
            'urql.key': operation.key,
            ...(operation.context._instance ? { 'urql.id': operation.context._instance } : {}),
        });
        const logger = (operation.context[FIDANT_LOGGER_KEY] ??= outerLogger.child(context));
        if (logger.info.isEnabled) {
            const { opName, op } = toLoggableOperation(operation);
            logger.info('graphql:request', { opName, request: op });
        }
    }
    else {
        const logger = operation.context[FIDANT_LOGGER_KEY];
        if (!logger) {
            return;
        }
        if (logger.debug.isEnabled) {
            const { opName, op } = toLoggableOperation(operation);
            logger.debug('graphql:teardown', { opName, request: op });
        }
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete operation.context[FIDANT_LOGGER_KEY];
        logger[Symbol.dispose]();
    }
}), forward, onPush(result => {
    const { operation } = result;
    if (operation.kind === 'teardown') {
        return;
    }
    const logger = operation.context[FIDANT_LOGGER_KEY];
    assert(logger, 'Logger must be set on operation context');
    if (!result.error) {
        if (logger.info.isEnabled) {
            const { opName, op } = toLoggableOperation(operation);
            logger.info('graphql:response', { opName, result: { ...result, operation: op } });
        }
    }
    else if (!logger.error.isEnabled) {
        return;
    }
    else if (!result.data) {
        // Total no-data failure. Log the full error result.
        const { opName } = toLoggableOperation(operation);
        logger.error(result.error, { opName, result });
    }
    else {
        const { opName, op } = toLoggableOperation(operation);
        // Log the error with stack trace etc, and the full result.
        logger.error(result.error, { opName, result: { ...result, operation: op } });
        // The info log just has the operation key for correlation.
        logger.info('graphql:response', { opName, result: { operation: { key: operation.key } } });
    }
}));
export default loggingExchange;
