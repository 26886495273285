import { cacheExchange } from '@urql/exchange-graphcache';
import { merge } from 'lodash-es';
import schemaJson from '@fidant-io/api-schema/schema.json' with { type: 'json' };
// HINT: look for '@graphql-eslint/strict-id-in-types' suppressions in the schema.
export const TypesWithNoIds = new Set([
    'Viewer',
    'ProfileField',
    'QuestScope',
    'QuestScopeDirectorySpec',
    'QuestScopeLlmFilterSpec',
    'TimestampInterval',
    'TimerStateReplaced',
    'TimerStateStarted',
    'TimerStateStopped',
    'TimerStateTriggered',
    'IncomingWebhook',
    'Credentials',
    'MessageEdited',
    'MessageReaction',
]);
export const createGraphCacheExchange = ({ logger, ...options } = {}) => cacheExchange({
    schema: schemaJson,
    ...(logger ? { logger: (severity, message) => void logger[severity](message) } : {}),
    ...options,
    updates: merge({
        Mutation: {
            createQuest: (_result, _args, cache) => {
                cache.invalidate('Viewer', 'quests');
            },
            addQuestEnrollmentEvaluation(_result, _args, cache) {
                // TODO
                void cache;
            },
        },
    }, options.updates ?? {}),
    keys: {
        ...Object.fromEntries([...TypesWithNoIds].map(k => [k, () => null])),
        ...options.keys,
    },
});
