import { composeExchanges } from '@urql/core';
import { configuredScalarExchange } from './configuredScalarExchange.js';
import { roundTripExchange } from './roundTripExchange.js';
/**
 * Either a `configuredScalarExchange`, which transforms custom scalar values like URL, and possibly round-tripping them
 * through JSON serialization if this is used in RSC (React Server Components); or a no-op exchange.
 */
export const scalarExchange = (config = 'default') => {
    if (config === 'default') {
        return configuredScalarExchange();
    }
    else if (config === 'rsc') {
        return composeExchanges([roundTripExchange(), configuredScalarExchange()]);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    }
    else if (config === 'raw') {
        // No-op exchange.
        return e => e.forward;
    }
    else {
        throw new Error(`Unknown scalar config: ${String(config)}`);
    }
};
